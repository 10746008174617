/*html,*/
/*body,*/
/*#root, */
/*#root div:only-child,*/
.gantt-container {
  height: 100%;
}

.wrapperIcon {
  display: flex;
  align-items: center;
  cursor: grab;
}

.hideProjectLine {
  display: none;
}

.hide .timeline_cell {
  display: none;
}
.hide .grid_cell {
  width: 100% !important;
}

.button {
  opacity: 0.5;
}

.button:hover {
  opacity: 1;
}

.delete-button {
  padding-left: 26px;
}

.story_task {
  border: 2px solid #bfc518;
  background: #f2f67e;
}

.story_task .gantt_task_content {
  color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
}

.story_task .gantt_task_progress {
  background: #d9df29;
}

.gantt_tree_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.delete-icon {
  color: #b60000;
}

.gantt_cell_weekend {
  background-color: #d54e4e;
  color: #fff !important;
  font-weight: bold;
}

.gantt_cell_weekend.gantt_task_cell {
  z-index: 1;
  opacity: 0.85;
}

.gantt_row.odd,
.gantt_task_row.odd {
  background: #efeffc;
}

.gantt_row,
.gantt_task_row {
  background: #fff;
}

.gantt_row {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.gantt_cell[data-column-name="name"] {
  flex-grow: 2;
  justify-content: flex-start !important;
}

.gantt_cell[data-column-name="start_date"] {
  flex-grow: 1;
  justify-content: flex-start !important;
}

.gantt_cell[data-column-name="totalHours"] .gantt_tree_content {
  justify-content: flex-start !important;
  margin-left: 10px;
}

.gantt_grid_scale {
  width: auto !important;
  min-width: 600px;
}

.gantt_grid_data {
  width: auto !important;
  min-width: 600px;
}

.gantt_grid_scale {
  display: flex;
}

.gantt_grid_head_name {
  flex-grow: 2;
  text-align: left;
  padding-left: 20px;
}

.gantt_grid_head_start_date {
  text-align: start;
  padding-left: 10px;
  flex: 1;
  min-width: 140px;
}

.gantt_hor_scroll {
  overflow-x: auto;
}
